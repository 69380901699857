import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { useContext } from 'react';
import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { getQueryParamKeyForWidget } from '../helpers/quilt/WidgetHelper';
import SectionWidgetContext from '@aurora/shared-client/components/context/SectionWidgetContext';

interface QueryKeyAndArray {
  /**
   * The generated query key for the widget.
   */
  key: EndUserQueryParams;
  /**
   * The query param value.
   */
  value: string[] | null;
}

/**
 * Creates a unique query param key based on the containing Widget, as well as returns
 * the value for that query param key.
 *
 * @param suffix optional name to append to the query param key to make it more specific.
 * @param defaultValue the default value to return when no query param exists for the key.
 *
 * @author rishabh.jivan
 */
export default function useQueryParamArrayForWidget(
  suffix: string | null = null,
  defaultValue: [] | null = null
): QueryKeyAndArray {
  const { router } = useEndUserRoutes();
  const widgetContext = useContext(WidgetContext);
  const { location } = useContext(SectionWidgetContext);
  const selectedWidgetId = getQueryParamKeyForWidget(widgetContext, location, suffix, '-');
  const key = selectedWidgetId as EndUserQueryParams;
  const value = router.getWrappedQueryParam(selectedWidgetId as EndUserQueryParams, defaultValue);
  return { key, value };
}
