import type { Tag } from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * An option in the tag picker.
 */
export default class TagOption {
  label: string;

  value: string;

  constructor(tag: Omit<Tag, 'messages'>) {
    this.label = tag.text;
    this.value = tag.id;
  }
}
