import nodeTagPropertiesQuery from '@aurora/shared-client/components/nodes/NodeProperties/NodeTagProperties.query.graphql';
import TagEditor from '@aurora/shared-client/components/tags/TagEditor/TagEditor';
import TagOption from '@aurora/shared-client/components/tags/TagEditor/TagOption';
import { TagsQueryFetchType } from '@aurora/shared-client/components/tags/TagEditor/TagSelector';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { Tag } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  NodeTagPropertiesQuery,
  NodeTagPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './WidgetTagsFilter.module.pcss';

interface Props {
  /**
   * An array of selected tags, e.g. through the querystring
   */
  selectedTags: string[];
  /**
   * Callback function when a tag is selected.
   *
   * @callback
   */
  onChange?: (items: TagOption[]) => void;
  /**
   * The node id which filtering should be scoped to.
   */
  contextNodeId: string;
}

/**
 * The tags filter for the ideas widget filter bar.
 *
 * @author rishabh.jivan
 */
const WidgetTagsFilter = ({ selectedTags, contextNodeId, onChange }: Props): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.WIDGET_TAGS_FILTER);

  const { data: propertiesData, loading: propertiesLoading } = useQueryWithTracing<
    NodeTagPropertiesQuery,
    NodeTagPropertiesQueryVariables
  >(module, nodeTagPropertiesQuery, {
    variables: {
      id: contextNodeId
    }
  });

  if (
    propertiesLoading ||
    propertiesData?.coreNode?.tagProperties?.tagsEnabled.failureReason !== null
  ) {
    return null;
  }

  /**
   * Filter a list of tags to only return unique tags
   * @param tags list of tags to filter
   * @returns the list of unique tags
   */
  function getUniqueTagsArray(tags: TagOption[]): TagOption[] {
    const uniqueArray: TagOption[] = [];
    for (const tagElement of tags) {
      if (!uniqueArray.some(tag => tag.value === tagElement.value)) {
        uniqueArray.push(tagElement);
      }
    }
    return uniqueArray;
  }

  const uniqueTags =
    selectedTags && selectedTags.length > 0
      ? getUniqueTagsArray(selectedTags.map(item => new TagOption({ id: item, text: item } as Tag)))
      : [];

  /**
   * Handler to add a new tag to the list of tags after it is selected using
   * the TagSelector component
   * @param tagOption The tag that has been selected using the TagSelector
   */
  async function onTagAdd(tagOption: TagOption): Promise<void> {
    if (!uniqueTags.some(tag => tag.value === tagOption.value)) {
      uniqueTags.push(tagOption);
      onChange(uniqueTags);
    }
  }

  /**
   * Handler to remove a tag from the list of selected tags
   * @param tag The tag to be removed from the list of selected tags
   */
  async function onTagRemove(tag: TagOption): Promise<void> {
    uniqueTags.splice(
      uniqueTags.findIndex(tagElement => tagElement.value === tag.value),
      1
    );
    onChange(uniqueTags);
  }

  return (
    <div className={cx('lia-flex-grid')}>
      <span className={cx('lia-filter-prefix-text')}>{i18n.formatMessage('prefixText')}:</span>
      <TagEditor
        onTagAdd={onTagAdd}
        onTagRemove={onTagRemove}
        showRemoveTagDialog={false}
        canTag={true}
        tags={uniqueTags}
        isPreview={true}
        canRemoveTag={true}
        isInlineAddTag={true}
        tagsQueryFetchType={TagsQueryFetchType.FETCH_TOP_TAGS}
        nodeId={contextNodeId}
      />
    </div>
  );
};

export default WidgetTagsFilter;
