import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type TagOption from '@aurora/shared-client/components/tags/TagEditor/TagOption';
import type {
  EndUserRouteAndParams,
  MessagePagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import React, { useCallback, useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import type { OverflowSetItem } from '../OverflowSet/OverflowSet';
import OverflowSet from '../OverflowSet/OverflowSet';
import WidgetTagsFilter from '../WidgetTagsFilter/WidgetTagsFilter';

interface Props {
  /**
   * The querystring parameter key used for tags
   */
  tagsParameterKey: string;
  /**
   * An array of selected tags, e.g. through the querystring
   */
  selectedTags: string[];
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Callback function when a filter is selected.
   *
   * @callback
   */
  onChange?: () => void;
  /**
   * The node scope to select tags
   */
  nodeId: string;
  /**
   * set of additional filter items
   */
  additionalFilters?: OverflowSetItem[];
}

/**
 * Renders filters for idea messages widget.
 *
 * @author rishabh.jivan
 */
const MessagesWidgetFilters = ({
  tagsParameterKey,
  selectedTags,
  className,
  onChange,
  nodeId,
  additionalFilters = []
}: Props): React.ReactElement => {
  const cx = useClassNameMapper();
  const i18n = useTranslation(EndUserComponent.IDEA_MESSAGES_WIDGET_FILTERS);
  const { loading: textLoading } = i18n;
  const { router } = useEndUserRoutes();
  const { contextNode } = useContext(AppContext);
  const contextNodeId = nodeId === null ? contextNode.id : nodeId;
  const beforeCursorValue = router.getQueryParam(EndUserQueryParams.BEFORE);
  const afterCursorValue = router.getQueryParam(EndUserQueryParams.AFTER);

  const onTagsFilterChange = useCallback(
    async (items: TagOption[]) => {
      if (onChange) {
        onChange();
      }
      await router.replaceRoute<EndUserRouteAndParams<EndUserPages>>(
        router.getCurrentPageName(),
        router.getPathParams<MessagePagesAndParams>(),
        {
          ...router.getQueryParams(),
          ...(afterCursorValue && { [EndUserQueryParams.AFTER]: null }),
          ...(beforeCursorValue && { [EndUserQueryParams.BEFORE]: null }),
          [tagsParameterKey]: items.map(item => item.label)
        },
        { scroll: false }
      );
    },
    [onChange, router, tagsParameterKey, afterCursorValue, beforeCursorValue]
  );

  if (textLoading) {
    return null;
  }

  let filtersItems: OverflowSetItem[] = [
    {
      key: 'MessagesTagsFilter',
      onRender: (
        <WidgetTagsFilter
          selectedTags={selectedTags}
          contextNodeId={contextNodeId}
          onChange={onTagsFilterChange}
        />
      )
    }
  ];

  if (additionalFilters.length > 0) {
    filtersItems = [...additionalFilters, ...filtersItems];
  }

  return <OverflowSet className={cx(className)} items={filtersItems} alwaysWrapOverflowed={true} />;
};

export default MessagesWidgetFilters;
